import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Blogs.css'; // Ensure you have this CSS file

function Blog() {
    const [blogs, setBlogs] = useState([]); // Store blogs
    const [page, setPage] = useState(1); // Track current page
    const [loading, setLoading] = useState(false); // Loading state
    const [hasMore, setHasMore] = useState(true); // Check if more blogs exist

    // Fetch blogs from the API
    useEffect(() => {
        const fetchBlogs = async () => {
            setLoading(true);
            try {
                const response = await fetch(`https://admin.quickassignment.co.uk/api/blog?page=${page}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data = await response.json();
                if (data.success && Array.isArray(data.data)) {
                    setBlogs((prevBlogs) => [...prevBlogs, ...data.data]); // Append new blogs
                    setHasMore(data.data.length > 0); // Check if there are more blogs
                } else {
                    setHasMore(false); // Stop loading if no more blogs
                }
            } catch (error) {
                console.error('Error fetching blog data:', error);
            }
            setLoading(false);
        };

        fetchBlogs();
    }, [page]);

    // Load more blogs
    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    return (
        <>
            {/* Header Section */}
            <div className="blog-header">
                <div className="container">
                   
                    <div className="breadcrumb mt-5">
                        <Link to="/">Home</Link> / <span>Blog</span>
                    </div>
                </div>
            </div>

            {/* Blog List Section */}
            <div className="container blog-container">
                <div className="row">
                    {blogs.map((blog) => (
                        <div key={blog.id} className="col-md-6 col-lg-4 mb-4">
                            <div className="blog-card" style={{height : '500px'}}>
                                <img src={blog.image} alt={blog.title} className="blog-image" />
                                <div className="blog-content">
                                    <h3>{blog.title}</h3>
                                    <p
                                        className="blog-description"
                                        dangerouslySetInnerHTML={{ __html: blog.description }}
                                    />
                                    <Link to={`/blog_details/${blog.slug}`} className="btn btn-primary">
                                        Read More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Load More Button */}
                {hasMore && (
                    <div className="text-center mt-4">
                        <button className="btn btn-load-more" onClick={loadMore} disabled={loading}>
                            {loading ? 'Loading...' : 'Load More'}
                        </button>
                    </div>
                )}
            </div>
        </>
    );
}

export default Blog;