import React, { useState } from "react";
import logo from "../../assets/image/logo.png";
import "./header.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useEffect } from "react";

function Header() {
  const [navbar, setNavbar] = useState(false);

  const changeBg = () => {
    if (window.scrollY >= 100) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBg);

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const location = useLocation(); // once ready it returns the 'window.location' object
  const [url, setUrl] = useState(null);
  useEffect(() => {
    setUrl(location.pathname);
  }, [location]);

  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);
  return (
    <>

        <button type="button" id="mobile-nav-toggle" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation" onClick={handleNavCollapse}><i class="lnr lnr-menu"></i></button>


        <header id="header" className={navbar ? "header-scrolled" : ""}>
          <div class="container main-menu">
            <div class="row align-items-center justify-content-between d-flex">
              <div id="logo">
                <Link to={"/"} onClick={goToTop}>
                  <img src={logo} style={{ height: 60 }} alt="logo" title="" />
                </Link>
              </div>
              <nav id="nav-menu-container">
                <ul class="nav-menu">
                  <li>
                    <NavLink to={"/"} onClick={goToTop} className={"underline" + (url === "/" ? " active2" : "")}>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="about" onClick={goToTop} className={"underline" + (url === "/about" ? " active2" : "")}>
                      About
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="blog" onClick={goToTop} className={"underline" + (url === "/blog" ? " active2" : "")}>
                      Blog
                    </NavLink>
                  </li>
                  {/* <li>
                    <NavLink to="gallery" onClick={goToTop} className={"underline" + (url === "/gallery" ? "active" : "")}>
                      Gallery
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="demo" onClick={goToTop} className={"underline" + (url === "/demo" ? "active" : "")}>
                      Demo Request
                    </NavLink>
                  </li> */}
                  <li>
                    <NavLink to="contact" onClick={goToTop} className={"underline" + (url === "/contact" ? " active2" : "")}>Contact</NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>


          <div className='d-sm-none  d-block bckg'>
            <div class={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarsExample09">
              <a className={"underline" + (url === "/" ? " nav-link tongle_text" : " nav-link text-white")} href="/">Home</a>
              <a className={"underline" + (url === "/about" ? " nav-link tongle_text" : " nav-link text-white")} href="/about">About</a>
              <a className={"underline" + (url === "/gallery" ? " nav-link tongle_text" : " nav-link text-white")} href="/gallery">Gallery</a>
              {/* <a className={"underline" + (url === "/blog" ?" nav-link tongle_text" : " nav-link text-white")} href="/blog">Blog</a> */}
              <a className={"underline" + (url === "/contact" ? " nav-link tongle_text" : " nav-link text-white")} href="/contact">Contact</a>
              {/* <a href="/contact" className="btn btn-sm btn-tongle nav-link text-white" >Contact</a> */}
            </div>
          </div>
        </header>
    </>
  );
}

export default Header;
