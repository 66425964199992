import './assets/css/linearicons.css'
import './assets/css/bootstrap.css'
import './assets/css/magnific-popup.css'
import './assets/css/nice-select.css'
import './assets/css/animate.min.css'
import './assets/css/main.css'


import { Routes, Route } from "react-router-dom";

//layouts
import Header from "./components/layouts/Header";
import Footer from "./components/layouts/Footer";
import Homepage from './components/Homepage/Homepage';
import About from './components/About/About'
import Events from './components/Events/Events'
import Gallery from './components/Gallery/Gallery'
import Contact from './components/Contact/Contact'
import Demo from './components/Demo/Demo'
import Faq from './components/FAQ/Faq'
import Blog from './components/Blogs/Blog'
import BlogDetails from './components/Blogs/BlogDetails'
import UnderMaintainance from './components/Maintainance/UnderMaintainance'

// Expert Page
import Accounting from './components/ExpertPage/Accounting';
import Businessmgnt from './components/ExpertPage/Businessmgnt';
import Economics from './components/ExpertPage/Economics'
import EnglishLitreture from './components/ExpertPage/EnglishLitreture'
import Finance from './components/ExpertPage/Finance'
import HealthCare from './components/ExpertPage/HealthCare'
import Hospitality from './components/ExpertPage/Hospitality'
import It from './components/ExpertPage/It'
import Marketing from './components/ExpertPage/Marketing'
import Networking from './components/ExpertPage/Networking'
import Nursing from './components/ExpertPage/Nursing'
import Tourism from './components/ExpertPage/Tourism'

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route exact path="About" element={<About />} />
        <Route exact path="events" element={<Events />} />
        <Route exact path="gallery" element={<Gallery />} />
        <Route exact path="contact" element={<Contact />} />
        <Route exact path="demo" element={<Demo />} />
        <Route exact path="faq" element={<Faq />} />
        <Route exact path="*" element={<UnderMaintainance />} />
        <Route exact path='blog' element={<Blog/>}/>
        <Route exact path="/blog_details/:slug" element={<BlogDetails />} />
        

        {/* Expert Page */}
        <Route exact path="accounting" element={<Accounting />} />
        <Route exact path="businessmgnt" element={<Businessmgnt />} />
        <Route exact path="economics" element={<Economics />} />
        <Route exact path="english_litreture" element={<EnglishLitreture />} />
        <Route exact path="finance" element={<Finance />} />
        <Route exact path="healthCare" element={<HealthCare />} />
        <Route exact path="hospitality" element={<Hospitality />} />
        <Route exact path="it" element={<It />} />
        <Route exact path="marketing" element={<Marketing />} />
        <Route exact path="networking" element={<Networking />} />
        <Route exact path="nursing" element={<Nursing />} />
        <Route exact path="tourism" element={<Tourism />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
