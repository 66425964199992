import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import './blogdetails.css'; // Optional: Add custom styles

function BlogDetails() {
    const { slug } = useParams(); // Get the slug from the URL
    const [blog, setBlog] = useState(null); // Store blog details
    const [loading, setLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Fetch blog details from the API
    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                const response = await fetch(`https://admin.quickassignment.co.uk/api/blog_details/${slug}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch blog details');
                }
                const data = await response.json();
                if (data.success) {
                    setBlog(data.data); // Set blog details
                } else {
                    setError('Blog not found');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogDetails();
    }, [slug]);

    if (loading) {
        return <div className="text-center">Loading...</div>;
    }

    if (error) {
        return <div className="text-center text-danger">{error}</div>;
    }

    if (!blog) {
        return <div className="text-center">No blog found.</div>;
    }

    return (
        <>
            {/* Header Section */}
            <div className="blog-details-header">
                <div className="container">
                    <h1>{blog.title}</h1>
                    <div className="breadcrumb">
                        <Link to="/">Home</Link> / <Link to="/blogs">Blog</Link> / <span>{blog.title}</span>
                    </div>
                </div>
            </div>

            {/* Blog Details Section */}
            <div className="container blog-details-container">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="blog-details-content">
                            <img src={blog.image} alt={blog.title} className="blog-details-image" />
                            <div
                                className="blog-details-description"
                                dangerouslySetInnerHTML={{ __html: blog.description }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetails;